<template>
  <div class="base-container">
    <el-form label-width="160px">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="单位名称" prop="typeId">
            <span>{{apply.name}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="电话" prop="address">
            <span>{{apply.phone}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="地址" prop="typeId">
            <span>{{apply.address}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="邮编" prop="address">
            <span>{{apply.postcode}}</span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <table class="unit-attr">
      <tr>
        <td rowspan="13" class="td1">
          申<br><br>报<br><br>单<br><br>位<br><br>属<br><br>性
        </td>
        <td class="td2 bg">主类</td>
        <td class="td3 bg">分类</td>
      </tr>
      <tr v-for="(item,i) in unitAttrList" :key="i">
        <td class="td2">
          <el-radio v-model="apply.mainClass" :label="item.id" name="mainClass">{{null}}</el-radio>{{item.name}}
        </td>
        <td class="td3">
          <el-checkbox-group v-model="apply.classify">
            <el-checkbox v-for="subitem in item.classifyList" :key="subitem.id" :label="subitem.id" style="width:100%">
              {{subitem.classifyName}}
            </el-checkbox>
          </el-checkbox-group>
        </td>
      </tr>
    </table>
    <table class="unit-attr">
      <tr>
        <td class="td1" style="font-size:16px">备注</td>
        <td>
          <el-input v-model="apply.memo" placeholder="" size="normal" type="textarea" readonly></el-input>
        </td>
      </tr>
    </table>
    <table class="unit-attr">
      <tr>
        <td class="td1" style="font-size:16px"></td>
        <td class="td4">审批情况</td>
        <td class="td5">
          <el-radio-group v-model="apply.approval">
            <el-radio v-for="(item,i) in [{id:0,label:'未审核'},{id:1,label:'经审核不合格'},{id:2,label:'审核合格'}]" :key="i" :label="item.id">
              {{item.label}}
            </el-radio>
          </el-radio-group>
        </td>
        <td class="td6">文号</td>
        <td style="width:20%">
          <el-input v-model="apply.approvalCode" size="normal" readonly></el-input>
        </td>
      </tr>
      <tr>
        <td class="td1" style="font-size:16px">消防审批</td>
        <td class="td4">验收情况</td>
        <td class="td5">
          <el-radio-group v-model="apply.accept">
            <el-radio v-for="(item,i) in [{id:0,label:'未审核'},{id:1,label:'经审核不合格'},{id:2,label:'审核合格'}]" :key="i" :label="item.id">
              {{item.label}}
            </el-radio>
          </el-radio-group>
        </td>
        <td class="td6">文号</td>
        <td style="width:20%">
          <el-input v-model="apply.acceptCode" size="normal" readonly></el-input>
        </td>
      </tr>
      <tr>
        <td class="td1" style="font-size:16px"></td>
        <td class="td4">开业前检查</td>
        <td class="td5">
          <el-radio-group v-model="apply.checks">
            <el-radio v-for="(item,i) in [{id:0,label:'未审核'},{id:1,label:'经审核不合格'},{id:2,label:'审核合格'}]" :key="i" :label="item.id">
              {{item.label}}
            </el-radio>
          </el-radio-group>
        </td>
        <td class="td6">文号</td>
        <td style="width:20%">
          <el-input v-model="apply.checkCode" size="normal" readonly></el-input>
        </td>
      </tr>
    </table>
    <table class="unit-attr">
      <tr>
        <td class="td1" style="font-size:16px" rowspan="10">消防设施</td>
        <td class="td7">火灾自动报警系统</td>
        <td class="td4">
          <el-switch v-model="apply.fireAutoAlarm" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
        <td class="td7">自动喷水灭火系统</td>
        <td class="td4">
          <el-switch v-model="apply.autoWaterFire" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
      </tr>
      <tr>
        <td class="td7">泡沬灭火系统</td>
        <td class="td4">
          <el-switch v-model="apply.frothFire" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
        <td class="td7">气体灭火系统</td>
        <td class="td4">
          <el-switch v-model="apply.gasFire" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
      </tr>
      <tr>
        <td class="td7">干粉灭火系统</td>
        <td class="td4">
          <el-switch v-model="apply.dryPowerFire" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
        <td class="td7">细水雾灭火系统</td>
        <td class="td4">
          <el-switch v-model="apply.waterMistFire" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
      </tr>
      <tr>
        <td class="td7">排烟系统</td>
        <td class="td4">
          <el-switch v-model="apply.dischargeSmoke" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
        <td class="td7">防烟系统</td>
        <td class="td4">
          <el-switch v-model="apply.preventSmoke" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
      </tr>
      <tr>
        <td class="td7">室内消防给水系统</td>
        <td class="td4">
          <el-switch v-model="apply.roomInsideWater" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
        <td class="td7">室外消防给水系统</td>
        <td class="td4">
          <el-switch v-model="apply.roomOutWater" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
      </tr>
      <tr>
        <td class="td7">疏散指示标志和应急照明</td>
        <td class="td4">
          <el-switch v-model="apply.light" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
        <td class="td7">消防供电配电</td>
        <td class="td4">
          <el-switch v-model="apply.electricity" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
      </tr>
      <tr>
        <td class="td7">火灾应急广播系统</td>
        <td class="td4">
          <el-switch v-model="apply.fireBroadcast" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
        <td class="td7">消防电梯</td>
        <td class="td4">
          <el-switch v-model="apply.elevator" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
      </tr>
      <tr>
        <td class="td7">其他系统</td>
        <td class="td4">
          <el-switch v-model="apply.other" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
        <td class="td7">防火分隔</td>
        <td class="td4">
          <el-switch v-model="apply.preventFire" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
      </tr>
      <tr>
        <td class="td7">灭火器</td>
        <td class="td4">
          <el-switch v-model="apply.cutFire" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
        <td class="td7">控制室联动控制设备功能</td>
        <td class="td4">
          <el-switch v-model="apply.deviceFunction" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1"></el-switch>
        </td>
      </tr>
    </table>
    <p style="text-align:left;margin-top:10px">注：1、各单位自行对照（附件1)，并在申报单位属性栏内选择一项打"V"(复制V符号替换口）。2、公共娱乐场所是指向公众开放的下列室内场 所：（1)影剧院、录像厅、礼堂等演出、放映场所；（2)舞厅、卡拉OK等歌舞娱乐场所；（3)具有娱乐功能的夜总会、音乐茶座和餐饮场所; (4)游艺、游乐场所；（5)保龄球馆、旱冰场、桑拿浴室等营业性健身、休闲场所。</p>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      unitAttrList: [],
      apply: {
        classify: []
      }
    }
  },
  created () {
    this.getUnitAttr()
  },
  methods: {
    async getUnit () {
      const data = {}
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      const { data: result } = await this.$axios.get('/fireImportanceUnitApplyForm/list', { params: data })
      if (result.code === 200) {
        if (result.data.length > 0) {
          result.data[0].classify = result.data[0].classify.split(',').map(Number)
          this.apply = result.data[0]
        }
      }
    },
    async getUnitAttr () {
      const { data: result } = await this.$axios.get('/fireImportanceUnitApplyForm/listAllUnitProperty')
      if (result.code === 200) {
        this.unitAttrList = result.data
        this.getUnit()
      }
    }
  }
}
</script>

<style lang="scss">
.base-container {
  width: 100%;
  height: 100%;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  color: #fff;
  ::v-deep .el-form {
    .el-form-item {
      border: 1px solid #ddd;
      border-bottom: none;
      margin: 0;
      .el-form-item__label {
        background-color: rgba(30, 74, 115, 0.5);
        border-right: 1px solid #ddd;
      }
      .el-input__inner {
        border: none;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-radius: 0;
        color: #fff;
      }
      .el-date-picker {
        border: none;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-radius: 0;
        color: #fff;
      }
    }
    .el-row:last-child {
      .el-form-item {
        border-bottom: 1px solid #ddd;
      }
    }
    .el-row {
      .el-col:first-child {
        padding-right: 0 !important;
        .el-form-item {
          border-right: none;
        }
      }
      .el-col:last-child {
        padding-left: 0 !important;
        .el-form-item {
          border-left: none;
          .el-input__inner {
            border-right: none;
          }
          .el-date-picker {
            border-right: none;
          }
        }
        .trans-background {
          .el-form-item__label {
            background-color: rgba(30, 74, 115, 0.5);
          }
        }
      }
    }
    .info-title {
      .el-col {
        .el-form-item {
          .el-form-item__label {
            background-color: #23527e;
            color: #fff;
            text-align: center;
            border-right: 1px solid #ddd;
          }
          background-color: #23527e;
          color: #fff;
          border-left: 1px solid #ddd !important;
        }
      }
    }
  }
  .unit-attr {
    width: 100%;
    border: 1px solid #ddd;
    border-top: none;
    box-sizing: border-box;
    border-collapse: collapse;
    table-layout: fixed;
    word-break: break-all;
    white-space: normal;
    .bg {
      background-color: rgba(30, 74, 115, 0.5);
      text-align: center !important;
    }
    td {
      height: 40px;
      .el-textarea__inner {
        background-color: transparent;
        color: #fff;
        border: none;
      }
      .el-input__inner {
        background-color: transparent;
        color: #fff;
      }
    }
    .td1 {
      font-size: 18px;
      width: 20%;
      border-right: 1px solid #ddd;
      background-color: rgba(30, 74, 115, 0.5);
    }
    .td2 {
      width: 30%;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      text-align: left;
      padding: 5px;
      box-sizing: border-box;
    }
    .td3 {
      width: 50%;
      border-bottom: 1px solid #ddd;
      text-align: left;
      padding: 5px;
      box-sizing: border-box;
      .el-checkbox {
        margin-bottom: 5px;
        color: #fff;
      }
    }
    .td4 {
      width: 15%;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      box-sizing: border-box;
    }
    .td5 {
      width: 40%;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      box-sizing: border-box;
      .el-radio {
        margin-bottom: 5px;
        color: #fff;
      }
    }
    .td6 {
      width: 5%;
      background-color: rgba(30, 74, 115, 0.5);
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      box-sizing: border-box;
    }
    .td7 {
      width: 25%;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      box-sizing: border-box;
    }
  }
  nav {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(
      90deg,
      rgba(35, 82, 126, 0.6) 0%,
      rgba(57, 184, 230, 0.3) 100%
    );
    text-align: center;
  }
  .building {
    span {
      color: #fff;
    }
  }
}
</style>
